.col-centered {
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

.vertical {
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  font-size: 15px; }

#runenvsign {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  color: white;
  background: rgba(255, 0, 255, 0.5);
  padding: 0 .5em;
  z-index: 10000; }

#page-loading {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
  background-image: url(/styles/img/loading.gif);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed; }

#page-loading > span {
  top: 60%;
  left: 0;
  width: 100%;
  text-align: center;
  position: absolute;
  font-size: 30px;
  color: #ffffff;
  font-weight: 100; }

.skin-blue .main-header .logo, .skin-blue .main-header .logo:hover {
  background-color: #3c8dbc; }
